<template>
  <div class="container">
    <img class="jupyter-logo" src="/Jupyter_logo.svg" alt="Jupyter Logo" />
    <h1>Select Lab Execution Site <b-icon class="tooltip-icon" v-tooltip="'This page is for selecting which site your notebooks will run on. Site selection has no impact on your notebook functionality.'" icon="question-circle-fill"/></h1>
    <div class="control select" v-for="site in sites" :key="site.id">
      <input type="radio" :id="site.id" :value="site" :disabled="!site.enabled" v-model="selection">
      <label :for="site.id" v-bind:class="{ disabled: !site.enabled }"><strong>{{ site.name }}</strong></label>
    </div>
    <button class="control btn" @click="selectSite(selection)">Select</button>
    <div class="logo-footer">
      <img class="logo" src="/EBRAINS_logo.svg" alt="EBRAINS Logo" />
      <img class="logo" src="/HBP_logo.svg" alt="HBP Logo" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'LabSelect',
  data() {
    return {
      selection: this.sites.length ? this.sites[0] : {}
    }
  },
  props: {
    sites: Array
  },
  methods: {
    selectSite(params) {
      this.$parent.selectSite(params);
    },
  },
}
</script>


<style>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.jupyter-logo {
  height: 150px;
  margin: 80px 0px;
}
.logo-footer {
  margin-top: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
}
.logo {
  height: 40px;
  margin: 0px 20px;
}
.control {
  width: 45%;
  margin-bottom: 5px;
  padding: 6px 0px;
}
.control>select {
  display: flex;
  align-items: center;
}
.select {
  border: 1px solid #ccc;
  border-radius: 2px;
}
.select>input {
  width: 10%;
}
.select>label {
  width: 90%;
}
.btn {
  color: #fff;
  background-color: #F37524;
  border-color: #E34F21;
  border-radius: 2px;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 1.43;
  margin-top: 15px;
}
.disabled {
  color: grey;
}
.tooltip-icon {
  color: grey
}
.tooltip {
  display: block !important;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>