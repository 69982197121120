export function getLabVarsFromPath(path) {
  // extract notebook and collab name from path
  // example: https://lab.ebrains.eu/user-redirect/lab/tree/drive/shared/collab_name/nb_name.ipynb
  const vars = [];
  try {
    if (path && path.toLowerCase().includes("shared")) {
      path = path.split("/");
      let nb_name = path.pop();
      nb_name = nb_name.substring(0, nb_name.lastIndexOf('.')) // remove extension
      vars.push({key: "LAB_NB_NAME", value: nb_name});
      const collab_name = path.pop();
      vars.push({key: "LAB_COLLAB_NAME", value: collab_name});
    }
  } catch (e) {
    console.error("Error in getLabVarsFromPath", e)
  }
  return vars;
}