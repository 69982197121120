<template>
  <div id="app">
    <LabSelect :sites="this.sites"/>
  </div>
</template>

<script>
import LabSelect from './components/LabSelect.vue'
import config from './config.json';
import { getLabVarsFromPath } from "./helpers.js";

export default {
  name: 'App',
  data() {
    return {
      sites: config.sites,
      url_pathname: null,
      url_search: null,
    }
  },
  methods: {
    selectSite(params) {
      let full_url;
      const base_url = params.url;
      try {
        const path = window.location.pathname;
        const search_params = new URLSearchParams(window.location.search);
        const lab_vars = getLabVarsFromPath(path); // get nb and collab name
        for (const lab_var of lab_vars) {
          search_params.append(lab_var.key, lab_var.value);
        }
        search_params.append("LAB_EXECUTION_SITE", params.id); // add selected execution site
        const query_string = `?${search_params.toString()}`
        full_url = new URL(path + query_string, base_url);
      } catch (error) {
        console.error("Error building url: ", error);
        full_url = new URL(base_url);
      }
      window.location.replace(full_url.href);
    },
  },
  components: {
    LabSelect
  }
}
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
}
</style>
